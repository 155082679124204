<template>
  <section>
    <div class="d-flex justify-content-end mb-2">
      <b-button :to="{ name: 'hangar-dashboard'}">
        {{ $t('dashboard.hangar.view_dashboard') }}
      </b-button>
    </div>
    <b-card v-if="selectedHangarVariant && (selectedHangarVariant.variantType === 'hangar' || selectedHangarVariant.variantType === 'outside_ramps') && $route.query.mode === 'timeGridDay'" class="border">
      <b-card-title>{{ $t('hangar.availability') }}</b-card-title>
      <div class="d-flex flex-column flex-sm-row justify-content-between">
        <div class="flex-column">
          <div v-for="slice in infoSlicesDay.slice(0, slicesNumber /2)" :key="slice.sliceNumber" class="mb-25 pr-sm-1">
            {{ $t('dashboard.description_slice', { sliceNumber: slice.sliceNumber, from: slice.footPrintScaleFrom, to: slice.footPrintScaleTo, max: slice.maxAircraftPlaces, places: (slice.maxAircraftPlaces - slice.numberOfAircrafts) }) }}
          </div>
        </div>
        <div class="flex-column">
          <div v-for="slice in infoSlicesDay.slice(slicesNumber /2, slicesNumber)" :key="slice.sliceNumber" class="mb-25 pr-sm-1">
            {{ $t('dashboard.description_slice', { sliceNumber: slice.sliceNumber, from: slice.footPrintScaleFrom, to: slice.footPrintScaleTo, max: slice.maxAircraftPlaces, places: (slice.maxAircraftPlaces - slice.numberOfAircrafts) }) }}
          </div>
        </div>
      </div>
    </b-card>
    <b-card v-if="selectedHangarVariant && (selectedHangarVariant.variantType === 'parking') && $route.query.mode === 'timeGridDay' && calendarData" class="border">
      <b-card-title>{{ $t('hangar.availability') }}</b-card-title>
      <div class="d-flex flex-column flex-sm-row justify-content-between">
        <div class="flex-column">
          <div class="mb-25 pr-sm-1">
            {{ $t('dashboard.parking.availability') }} : {{ calendarData.maxAircraftPlaces ? calendarData.maxAircraftPlaces : 0 }}
          </div>
          <div class="mb-25 pr-sm-1">
            {{ $t('dashboard.parking.used') }} : {{ calendarData.numberOfAircrafts ? calendarData.maxAircraftPlaces - calendarData.numberOfAircrafts : calendarData.maxAircraftPlaces }}
          </div>
        </div>
      </div>
    </b-card>

    <b-card class="border mb-0">
      <div class="d-flex">
        <div class="flex-column pr-2">
          <app-btn-add v-if="$can('DASHBOARD_HANGAR_ADD')" @click="eventMode = 'add'">{{ $t('trip.sidebar.add_an_event') }}</app-btn-add>

          <app-select
            id="hangarVariantId"
            v-model="selectedHangarVariant"
            class="my-2"
            style="width: 200px"
            :placeholder="$t('dashboard.hangar.hangar')"
            :options="hangarsVariantsOptions"
            :searchable="false"
            :clearable="false"
            v-bind="variantsDataMeta"
            paginated
            :overlay="hangarsVariantsOptionsLoading"
            @option:selected="fetchHangarsVariantsDashboard()"
            @click:previous-page="fetchHangarsVariants(variantsDataMeta.previousPage)"
            @click:next-page="fetchHangarsVariants(variantsDataMeta.nextPage)"
          >
            <template #selected-option="{ productName }">
              {{ productName | trans }}
            </template>
            <template #option="{ productName }">
              {{ productName | trans }}
            </template>
          </app-select>

          <template v-if="selectedHangarVariant && (selectedHangarVariant.variantType === 'hangar' || selectedHangarVariant.variantType === 'outside_ramps')">
            <div v-if="slicesNumber > 1" class="mt-1">
              <b-form-checkbox
                :checked="selectedSlices.length === slicesNumber"
                stacked
                @change="selectAllSlices()"
              >
                <div class="">{{ $t('common.all') }}</div>
              </b-form-checkbox>
            </div>
            <div v-for="(elem, index) in slicesNumber" :key="index">
              <div class="mt-1">
                <b-form-checkbox
                  v-model="selectedSlices"
                  :value="index + 1"
                  stacked
                >
                  <div class="">{{ $t('dashboard.table.slice') }} {{ index + 1 }}</div>
                </b-form-checkbox>
              </div>
            </div>
          </template>
        </div>

        <div class="flex-grow-1 mb-2">
          <full-calendar ref="fullCalendar" :options="calendarOptions" class="full-calendar" />
        </div>
      </div>
    </b-card>

    <app-sidebar-form
      form-name="add-event"
      :edition-mode-title="eventMode"
      :edition="!!eventMode"
      :title="$tc('common.aircraft', 1)"
      :external-request="onSubmit"
      :entity="eventEntity"
      :show-delete="eventMode === 'edit'"
      @delete="modalDeletecustomTrip(eventEntity.id)"
      @update:edition="val => {
        if (!val) {
          eventMode = null
          eventEntity = eventEntityInit
        }
      }"
    >
      <template #fields="{ item }">
        <div v-if="eventMode === 'edit'" class="mb-2">
          <div class="mb-2">{{ $tc('common.aircraft', 1) }}: {{ item.title }}</div>
          <div v-if="selectedHangarVariant && (selectedHangarVariant.variantType === 'hangar' || selectedHangarVariant.variantType === 'outside_ramps')" class="mb-2">{{ $t('dashboard.table.slice') }}: {{ item.sliceNumber }}</div>
        </div>

        <template v-else>
          <app-input
            id="title"
            v-model="item.title"
            :label="$t('dashboard.title')"
            autofocus
            required
          />
          <validation-provider #default="{}" :name="$t('dashboard.table.slice')" rules="required" class="validation-required">
            <b-form-group
              v-if="selectedHangarVariant && (selectedHangarVariant.variantType === 'hangar' || selectedHangarVariant.variantType === 'outside_ramps')"
              :label="$t('dashboard.table.slice')"
              label-for="slice"
            >
              <v-select
                v-model="item.sliceNumber"
                input-id="slice"
                :options="sliceNumberOptions"
                :clearable="false"
              />
            </b-form-group>
          </validation-provider>
        </template>

        <validation-provider>
          <b-form-group :label="$t('dashboard.start_date')" class="mb-1" label-for="dateStart">
            <flat-pickr
              id="dateStart"
              v-model="item.startAt"
              :config="{
                enableTime: true,
                altInput: true,
                altFormat: 'F j, Y H:i',
                dateFormat: 'Z',
                localize: $i18n.locale,
                time_24hr: true,
              }"
              class="form-control"
            />
          </b-form-group>
        </validation-provider>

        <validation-provider>
          <b-form-group :label="$t('dashboard.end_date')" class="mb-1" label-for="dateEnd">
            <flat-pickr
              id="dateEnd"
              v-model="item.endAt"
              :config="{
                enableTime: true,
                altInput: true,
                altFormat: 'F j, Y H:i',
                dateFormat: 'Z',
                localize: $i18n.locale,
                time_24hr: true,
              }"
              class="form-control"
            />
          </b-form-group>
        </validation-provider>

        <validation-provider>
          <b-form-group :label="$t('dashboard.customer')" label-for="customer" class="mb-0">
            <v-select
              v-model="item.customerContract"
              input-id="customer"
              :get-option-label="option => option.companyName"
              :options="[{ companyName: $t('dashboard.custom'), id: null }].concat(negotiatedRates)"
              :clearable="false"
              :disabled="!!item.id"
              @option:selected="item.clientName = null"
            />
          </b-form-group>
          <div class="mb-2"><i>{{ $t('dashboard.customer_notice') }}</i></div>
        </validation-provider>

        <app-input
          v-if="!item.customerContract.id"
          id="clientName"
          v-model="item.clientName"
          :label="$t('dashboard.client_name')"
          :disabled="!!item.id"
        />

        <validation-provider>
          <b-form-group :label="$t('dashboard.internal_description')" label-for="internalDescription">
            <b-form-textarea
              id="internalDescription"
              v-model="item.internalDescription"
              :placeholder="$t('dashboard.internal_description')"
              rows="5"
            />
            <span><i>{{ $t('dashboard.internal_description_notice') }}</i></span>
          </b-form-group>
        </validation-provider>
      </template>
    </app-sidebar-form>
  </section>
</template>

<script>
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { omit, pick } from 'lodash'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import AppBtnAdd from '@/components/AppBtnAdd.vue'
import AppSidebarForm from '@/components/AppSidebarForm.vue'
import AppDatepicker from '@/components/AppDatepicker.vue'
import AppSelect from '@/components/AppSelect.vue'
import {
  deleteHangarCustomEventRequest,
  fetchHangarCustomEventRequest,
  fetchHangarsVariantsCalendarRequest,
  fetchHangarsVariantsCalendarSlicesRequest,
  patchHangarCustomEventRequest,
  postHangarCustomEventRequest,
} from '@/request/globalApi/requests/hangarRequests'

export default defineComponent({
  name: 'DashboardCalendar',

  components: {
    AppBtnAdd,
    AppSidebarForm,
    AppDatepicker,
    AppSelect,
    FullCalendar,
  },

  setup(_props, ctx) {
    // eslint-disable-next-line object-curly-newline
    const { $i18n, $moment, $store, $router, $can, $bvModal, _cloneDeep } = ctx.root

    const getDay = day => (day ? $moment(day).format('YYYY-MM-DD') : null)
    const currentDate = ref($moment().locale($i18n.locale))

    // For v-select paginated
    const variantsDataMeta = computed(() => pick($store.state.hangarVariant, 'previousPage', 'nextPage', 'lastPage', 'totalItems'))

    const queries = ref({})
    const calendarData = ref(null)

    const infoSlicesDay = ref([])

    // Map state
    const slicesNumber = computed(() => $store.state.hangarVariant.slicesNumber)
    const hangarsVariantsOptions = computed(() => $store.state.hangarVariant.hangarsVariantsOptions)
    const hangarsVariantsOptionsLoading = computed(() => $store.state.hangarVariant.hangarsVariantsOptionsLoading)
    const negotiatedRates = computed(() => $store.state.negotiatedRate.negotiatedRates)
    const selectedHangarVariant = computed({
      get() {
        return $store.state.hangarVariant.selectedHangarVariant
      },
      set(hangarVariant) {
        $store.commit('hangarVariant/SET_SELECTED_HANGAR_VARIANT', hangarVariant)
      },
    })
    const selectedSlices = computed({
      get() {
        return $store.state.hangarVariant.selectedSlices
      },
      set(slices) {
        $store.commit('hangarVariant/SET_SELECTED_SLICES', slices)
      },
    })

    const sliceNumberOptions = computed(() => {
      const options = []
      for (let i = 1; i <= slicesNumber.value; i++) {
        options.push(i)
      }
      return options
    })

    const fetchHangarsVariantsDashboard = (variantId = ctx.root.$route.query.vid, queryParams = {}) => {
      const query = {
        date: $moment(currentDate.value).locale('en').format('DD-MM-YYYY'),
        ...queryParams,
      }
      $store.dispatch('hangarVariant/fetchHangarsVariantsDashboard', { variantId, queryParams: query })
    }

    const eventEntity = ref({
      title: null,
      variantId: null,
      sliceNumber: null,
      startAt: null,
      endAt: null,
      customerContract: { companyName: $i18n.t('dashboard.custom'), id: null },
      clientName: null,
      internalDescription: null,
    })
    const eventEntityInit = ref(_cloneDeep(eventEntity.value))

    const eventMode = ref(null)
    const hangarEditLoading = ref(false)

    const selectAllSlices = () => {
      if (selectedSlices.value.length === slicesNumber.value) selectedSlices.value = []
      else {
        const slices = []
        for (let i = 1; i <= slicesNumber.value; i++) {
          slices.push(i)
        }
        $store.commit('hangarVariant/SET_SELECTED_SLICES', slices)
      }
    }

    $store.commit('negotiatedRate/SET_NEGOCIATED_RATES_LIGHT_MODE', true)
    $store.dispatch('negotiatedRate/fetchNegotiatedRates', true)

    $store.dispatch('hangarVariant/fetchHangarSlicesNumber')

    const fetchHangarsVariants = page => {
      const neededRefresh = page && $store.state.hangarVariant.page !== page && !hangarsVariantsOptions[0]
      if (page) $store.commit('hangarVariant/SET_PAGE', page)
      $store.dispatch('hangarVariant/fetchHangarsVariants', neededRefresh).then(() => {
        if (hangarsVariantsOptions.value[0] && !page) {
          let hangar = hangarsVariantsOptions.value[0]

          // If a hangarVariantId is preselected by the url, select it instead of the store's selectedHangarVariant, only if it available in the options
          if (ctx.root.$route.query.vid) {
            hangar = hangarsVariantsOptions.value.find(hangarVariant => hangarVariant.id === Number(ctx.root.$route.query.vid)) || hangar
          }
          $store.commit('hangarVariant/SET_SELECTED_HANGAR_VARIANT', hangar)
        }
      })
    }
    fetchHangarsVariants()

    const fullCalendar = ref(null)

    const calendarOptions = reactive({
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
      initialView: ctx.root.$route.query.mode,
      initialDate: $moment(ctx.root.$route.query.start).endOf('isoWeek').format('YYYY-MM-DD'),
      headerToolbar: {
        start: 'sidebarToggle, prev, next, title',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,today',
      },
      views: { dayGridMonth: { displayEventEnd: true } },
      events: [],
      datesSet: event => {
        if (getDay(event.start) === queries.value.startAt
          && queries.value.mode === event.view.type
        ) return

        queries.value = {
          vid: ctx.root.$route.query.vid || selectedHangarVariant.value?.id,
          start: getDay(event.start),
          end: getDay(event.end),
          mode: event.view.type,
        }
        $router.replace({ query: { ...ctx.root.$route.query, ...queries.value, end: undefined } })
      },
      eventClick: calEvent => {
        if (calEvent.event.extendedProps.isCustom) {
          if ($can('DASHBOARD_HANGAR_EDIT')) {
            hangarEditLoading.value = true
            fetchHangarCustomEventRequest(calEvent.event.id).then(({ data }) => {
              eventEntity.value = {
                ...omit(data, 'slice', 'customerContract'),
                customerContract: data.customerContract || eventEntity.value.customerContract,
              }
              if (selectedHangarVariant.value.variantType === 'hangar' || selectedHangarVariant.value.variantType === 'outside_ramps') {
                eventEntity.value.sliceNumber = data.slice.sliceNumber
              } else {
                delete eventEntity.value.sliceNumber
              }
              eventMode.value = 'edit'
              hangarEditLoading.value = false
            })
          }
        } else {
          $router.push({ name: 'trip-details', params: { trip_id: calEvent.event.id } })
        }
      },
      textEscape: false,
      eventResizableFromStart: true,
      fixedWeekCount: false,
      weekNumberCalculation: 'ISO',
      dragScroll: true,
      navLinks: true,
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
        meridiem: false,
      },
      slotLabelFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
        meridiem: false,
      },
      locale: $i18n.locale,
    })

    const fetchHangarsVariantsCalendar = async (variantId = selectedHangarVariant.value?.id) => {
      const query = {
        ...pick(queries.value, 'start', 'end'),
        sliceNumbers: selectedSlices.value,
      }
      return fetchHangarsVariantsCalendarRequest(variantId, query).then(({ data }) => {
        calendarData.value = data
        calendarOptions.events = data.aircrafts.map(aircraft => {
          const tripId = aircraft.tripId || aircraft.customEventId
          return {
            title: aircraft.title,
            id: tripId,
            groupId: tripId,
            isCustom: !!aircraft.customEventId,
            start: $moment(aircraft.start).format('YYYY-MM-DD HH:mm'),
            end: $moment(aircraft.end).format('YYYY-MM-DD HH:mm'),
            color: '#DCC181',
            textColor: 'white',
          }
        })
      })
    }

    const modalDeletecustomTrip = customEventId => {
      $bvModal
        .msgBoxConfirm($i18n.t('alert.delete_confirmation.message'), {
          title: $i18n.t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: $i18n.t('common.yes'),
          cancelTitle: $i18n.t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(ok => {
          if (ok) {
            deleteHangarCustomEventRequest(customEventId).then(() => {
              eventMode.value = null
              fetchHangarsVariantsCalendar()
            })
          }
        })
    }

    watch([selectedSlices, selectedHangarVariant, () => ctx.root.$route.query], () => {
      eventEntity.value.variantId = selectedHangarVariant.value?.id
      if (selectedHangarVariant.value) {
        eventEntity.value.variantId = selectedHangarVariant.value.id
        eventEntityInit.value.variantId = selectedHangarVariant.value.id
        if (queries.value.start) {
          $router.replace({ query: { ...ctx.root.$route.query, vid: selectedHangarVariant.value.id } })
          fetchHangarsVariantsCalendar()
        }
        if (ctx.root.$route.query.mode === 'timeGridDay') {
          const queryParams = { date: queries.value.start }
          fetchHangarsVariantsCalendarSlicesRequest(selectedHangarVariant.value.id, queryParams).then(({ data }) => {
            infoSlicesDay.value = data.slices
          })
        }
      }
    }, { immediate: true })

    const onSubmit = async payload => {
      let action = patchHangarCustomEventRequest
      const parsePayload = {
        ...omit(payload, 'customerContract'),
        customerContractId: payload.customerContract.id,
      }

      if (eventMode.value === 'add') action = postHangarCustomEventRequest
      return action(parsePayload).then(() => {
        fetchHangarsVariantsCalendar()
        if (ctx.root.$route.query.mode === 'timeGridDay') {
          const queryParams = { date: queries.value.start }
          fetchHangarsVariantsCalendarSlicesRequest(selectedHangarVariant.value.id, queryParams).then(({ data }) => {
            infoSlicesDay.value = data.slices
          })
        }
      })
    }

    return {
      negotiatedRates,
      hangarsVariantsOptions,
      hangarsVariantsOptionsLoading,
      fetchHangarsVariantsDashboard,
      slicesNumber,
      sliceNumberOptions,
      selectedSlices,
      selectAllSlices,
      calendarOptions,
      fullCalendar,
      eventEntity,
      eventEntityInit,
      eventMode,
      hangarEditLoading,
      selectedHangarVariant,
      variantsDataMeta,
      infoSlicesDay,
      fetchHangarsVariants,
      fetchHangarsVariantsCalendar,
      modalDeletecustomTrip,
      onSubmit,
      calendarData,
    }
  },
})
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
.fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100%!important;
}
.fc-scrollgrid-sync-table{
  width: 100%!important;
}
.fc-col-header {
  width: 100%!important;
}
.fc-addTarifsBtn-button {
  border-color: #DCC181 !important;
  background-color: #DCC181 !important;
}
</style>
